































































import { Vue, Component } from "vue-property-decorator";
import Breadcrumb from "@/components/home/Breadcrumb.vue";
import { Tabs, TabPane } from "element-ui";
@Component({
    components: {
        "al-breadcrumb": Breadcrumb,
        "el-tabs": Tabs,
        "el-tab-pane": TabPane
    }
})
export default class ProjectList extends Vue {
    field: {} = {}; //领域

    activeName: any = "1"; //tab actived
    caseListData: any = []; //列表数据
    loading1: any = true; //是否显示loading遮罩

    handleClick(event: any) {
        // console.log(event)
        this.caseListData = []; //清空列表
        this.getCaseList(event.name); //加载新的列表数据
    }

    //排序
    sortRule(a: any, b: any) {
        if (a.jgname_en.charCodeAt(0) > b.jgname_en.charCodeAt(0)) {
            return 1;
        } else if (a.jgname_en.charCodeAt(0) < b.jgname_en.charCodeAt(0)) {
            return -1;
        } else {
            if (
                a.jgname_en.toLowerCase().charCodeAt(1) >
                b.jgname_en.toLowerCase().charCodeAt(1)
            ) {
                return 1;
            } else if (
                a.jgname_en.toLowerCase().charCodeAt(1) <
                b.jgname_en.toLowerCase().charCodeAt(1)
            ) {
                return -1;
            } else {
                return 0;
            }
        }
    }

    //请求列表数据
    getCaseList(field: any) {
        const getopt = this.$api.apiList.projectList_2020;
        const opt = getopt(field);
        const that = this;
        this.$axios({
            method: opt.method,
            url: opt.url
        }).then((res: any) => {
            console.log(res.data);
            // this.caseListData = res.data;
            let _data = res.data;

            this.loading1 = false;
            for (const item of _data) {
                that.caseListData.push(item);
                // console.log(item);
            }
        });
    }

    //点击项目跳转详情页
    handelProject(e: any, item: any, key: any) {
        this.$router.push({ name: "project2020", params: { pid: item.id } });
    }

    //获取领域参数
    getField() {
        const getopt = this.$api.apiList.field_2020;
        const opt = getopt();
        this.$axios({
            url: opt.url,
            method: opt.method
        }).then((res: any) => {
            this.field = res.data;
        });
    }

    mounted() {
        this.getField();
        this.getCaseList(this.activeName);
    }
}
